<template>
  <div>
    <div class="invoice">
      <!-- begin invoice-company -->

      <div class="invoice-content">
      <h4 class="f-w-500">
        <i class="fas fa-fw m-r-10 fa-building text-aqua-darker"></i>
        企业信息
      </h4>
      <div class="row">
        <dl class="row col-lg-4">
          <dt class="text-inverse text-right col-6 text-truncate">
            社会信用代码:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.creditCode }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            所属行业:
          </dt>
          <dd class="col-6 text-truncate">
            <dictSelect
              :show="true"
              :value="BasicInfomation.industryId"
            ></dictSelect>
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            所属网格:
          </dt>
          <dd class="col-6 text-truncate">
            {{ gridheadEquipmentDict }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            所属区域:
          </dt>
          <dd class="col-6 text-truncate">
            <dictSelect
              :show="true"
              :value="BasicInfomation.regionId"
            ></dictSelect>
          </dd>
        </dl>
        <dl class="row col-lg-4">
          <dt class="text-inverse text-right col-6 text-truncate">
            法人代表:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.legal }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            法人身份证号:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.legalIdCard }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            联系电话:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.legalTel }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            主要产品:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.mainProduct }}
          </dd>
        </dl>
        <dl class="row col-lg-4">
          <dt class="text-inverse text-right col-6 text-truncate">
            生产许可编号:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.productionNum }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            从业人数:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.jobNum }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            建筑面积:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.floorage }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            产值:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.outputValue }}
          </dd>
        </dl>
        </div>
      </div>
      <!-- end invoice-company -->
      <!-- begin invoice-header -->
      <!-- 主要负责人 -->
      <div class="invoice-content bg-light">
        <h4 class="f-w-500">
          <i class="fas fa-fw m-r-10 fa-user-circle text-aqua-darker"></i>
          主要负责人
        </h4>
        <div class="row">
          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              姓名:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainUserName }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              电话号码:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainTel }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              证件号:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainIdCard }}
            </dd>
          </dl>
          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              是否持证:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainIsCard ? "是" : "否" }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              证件有效期:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainValidity }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              安全生产诚信等级:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainSincerityLevel }}
            </dd>
          </dl>
          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              标准等级:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainStandardLevel }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              安全化等级:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.mainSafeLevel }}
            </dd>
          </dl>
        </div>
      </div>
      <!-- 制度建设 -->
      <h4 class="f-w-500">
        <i class="fas fa-fw m-r-10 fa-th-list text-aqua-darker"></i>
        制度建设
      </h4>
      <div class="invoice-content row form-group m-b-10">
        <div class="row col-xl-4 col-lg-6">
          <dt class="text-inverse text-right col-6 text-truncate">
            有安全生产责任书:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.isSafeBook ? "是" : "否" }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            有安全生产规章制度及操作规范:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.isFGMP ? "是" : "否" }}
          </dd>
        </div>
        <div class="row col-xl-4 col-lg-6">
          <dt class="text-inverse text-right col-6 text-truncate">
            有安全隐患排查制度:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.isDangerCheck ? "是" : "否" }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            有教育培训制:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.isEduTrain ? "是" : "否" }}
          </dd>
        </div>
        <div class="row col-xl-4 col-lg-6">
          <dt class="text-inverse text-right col-6 text-truncate">
            有设备检查与检修制度:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.isCheckUp ? "是" : "否" }}
          </dd>
          <dt class="text-inverse text-right col-6 text-truncate">
            有工伤保险:
          </dt>
          <dd class="col-6 text-truncate">
            {{ BasicInfomation.isInjuryInsure ? "是" : "否" }}
          </dd>
        </div>
      </div>
      <hr class="p-t-2" />
      <!-- 安全管理人员 -->
      <div class="table-responsive">
        <span class="hidden-print"> </span>
        <h4 class="f-w-500">
          <i class="fas fa-fw m-r-10 fa-street-view text-aqua-darker"></i>
          安全管理人员
          <a href="javascript:;" v-on:click="editEvent()" class="btn btn-white"
            ><i class="fas fa-fw m-r-2 fa-plus"></i>新增</a
          >
        </h4>

        <table class="table table-bordered table-invoice table-striped">
          <thead>
            <tr>
              <th class="text-center" width="50px">序号</th>
              <th class="text-center" width="10%">姓名</th>
              <th class="text-center" width="10%">身份证</th>
              <th class="text-center" width="10%">持证情况</th>
              <th class="text-center" width="20%">电话</th>
              <th>证书有效期</th>
              <th>证书号</th>
              <th>是否是负责人</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="(item, index) in OfficertableData" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item["name"] }}</td>
              <td>{{ item["idCard"] }}</td>
              <td>{{ item["isCard"] ? "是" : "否" }}</td>
              <td>{{ item["tel"] }}</td>
              <td>{{ item["cardValidity"] }}</td>
              <td>{{ item["cardNum"] }}</td>
              <td>{{ item["isMain"] ? "是" : "否" }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <hr class="p-t-2" />
      <!-- 特种作业人员 -->
      <div class="table-responsive">
        <span class="pull-right hidden-print"> </span>
        <h4 class="f-w-500">
          <i class="fas fa-fw m-r-10 fa-user-secret text-aqua-darker"></i>
          特种作业人员
          <a
            href="javascript:;"
            v-on:click="SpecialoperatoreditEvent()"
            class="btn btn-white"
            ><i class="fas fa-fw m-r-2 fa-plus"></i>新增</a
          >
        </h4>
        <table class="table table-bordered table-invoice table-striped">
          <thead>
            <tr>
              <th class="text-center" width="50px">序号</th>
              <th class="text-center" width="10%">姓名</th>
              <th class="text-center" width="10%">持证情况</th>
              <th class="text-center" width="20%">电话</th>
              <th>证书有效期</th>
              <th>证书号</th>
            </tr>
          </thead>
          <tbody class="text-center">
            <tr v-for="(item, index) in SpecialoperatortableData" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item["name"] }}</td>
              <td>{{ item["isCard"] ? "是" : "否" }}</td>
              <td>{{ item["tel"] }}</td>
              <td>{{ item["cardValidity"] }}</td>
              <td>{{ item["cardNum"] }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <hr class="p-t-2" />
      <!-- 消防器材登记 -->
      <div class="invoice-content bg-light">
        <h4 class="f-w-500 p-t-10">
          <i class="fas fa-fw m-r-10 fa-fire-extinguisher text-red"></i>
          消防器材登记
        </h4>
        <div class="row">
          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              灭火器数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.extinguisher }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              沙驰数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.basin }}
            </dd>
          </dl>

          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              消防栓数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.hydrant }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              其他数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.other }}
            </dd>
          </dl>

          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              消防泵数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.firePumps }}
            </dd>
          </dl>
        </div>
      </div>
      <hr class="p-t-2" />
      <!-- 特种设备登记 -->
      <div class="invoice-content">
        <!-- 容压器 -->
        <div class="table-responsive ">
          <span class="pull-right hidden-print"> </span>
          <h4 class="f-w-500">
            <i class="fas fa-fw m-r-10 fa-truck text-green-darker"></i>
            特种设备登记
            <a
              href="javascript:;"
              v-on:click="PressurevesseleditEvent()"
              class="btn btn-white"
              ><i class="fas fa-fw m-r-2 fa-plus"></i>新增</a
            >
          </h4>
          <table class="table table-bordered table-invoice table-striped">
            <thead>
              <tr>
                <th class="text-center" width="50px">序号</th>
                <th class="text-center" width="100px">容压器</th>
                <th class="text-center" width="100px">数量</th>
                <th class="text-center" width="100px">单位</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr v-for="(item, index) in RtableData" :key="index">
                <td>{{ index + 1 }}</td>
                <td>{{ formatdictId(item["dictId"]) }}</td>
                <td>{{ item["num"] }}</td>
                <td>{{ item["unit"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="invoice-content bg-light">
        <div class="row">
          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              锅炉数量(个):
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.boiler }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              专用机动车数量(辆):
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.car }}
            </dd>
          </dl>

          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              起重机数量(辆):
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.crane }}
            </dd>
            <dt class="text-inverse text-right col-6 text-truncate">
              电梯数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.elevator }}
            </dd>
          </dl>

          <dl class="row col-xl-4 col-lg-6">
            <dt class="text-inverse text-right col-6 text-truncate">
              特种其他数量:
            </dt>
            <dd class="col-6 text-truncate">
              {{ BasicInfomation.specialOther }}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import PageOptions from '../config/PageOptions.vue'
import { businessApi } from "@/api/system/businessApi";
import Officer from "../Officer.vue";
import Specialoperator from "../Specialoperator.vue"; //特种作业人员跳出框
import CompanyInfomation from "../Infomation.vue"; //导入企业信息编辑跳出框
import Pressurevessel from "../Pressurevessel.vue"; //容压器表格跳出框
import { dictApi } from "@/api/system/dictApi";
import dictSelect from "@/components/dictSelect/index.vue";
import { gridheadApi } from "@/api/systembasic/gridheadApi";
export default {
  name: "Companyinfomation",
  props: {
    id: {
      type: String,
      default: "",
    },
  },

  components: {
    dictSelect,
  },
  data() {
    return {
      BasicInfomation: {
        name: "",
        address: "",
        regionId: "",
        gridId: "",
        industryId: "",
        safetyLevel: "2",
        businessLicense: "",
        creditCode: "",
        legal: "",
        legalIdCard: "",
        legalTel: "",
        mainProduct: "",
        productionLicense: "",
        productionNum: "",
        jobNum: "",
        floorage: "",
        outputValue: "",
        isDangerous: "true",
        mainUserName: "",
        mainTel: "",
        mainIdCard: "",
        mainIsCard: "true",
        mainValidity: "",
        mainSincerityLevel: "",
        mainStandardLevel: "",
        mainSafeLevel: "",
        isSafeBook: "true",
        isEduTrain: "true",
        isFGMP: "true",
        isCheckUp: "true",
        isDangerCheck: "true",
        isInjuryInsure: "true",
        extinguisher: "",
        hydrant: "",
        firePumps: "",
        other: "",
        basin: "",
        boiler: "",
        crane: "",
        elevator: "",
        car: "",
        specialOther: "",
        longitude: "0",
        latitude: "0",
        id: "",
      }, //企业基本信息data
      gridheadEquipmentDict: "", //网格长name
      TrainingData: {}, //安全培训时间线数据
      SecurityCheckData: {}, //安全检查数据源
      SpecialEquipmentDict: [],
      RtableData: [], //容压器表格数据
      OfficertableData: [], //安全员表格数据
      SpecialoperatortableData: [], //特种作业人员表格数据
      imageList1: "",
      businessId:"",//也页面传值企业Id
      tablePage: {
        currentPage: 1,
        pageSize: 100,
        totalResult: 0,
      }, //表格分页属性
      //容压器下拉选择项
      formatdictId(value) {
        if (value === "3a01a6a5-3c99-0bd8-849e-6cb54102ccce") {
          return this.SpecialEquipmentDict[0].label;
        }
        if (value === "3a01a6a5-5b34-1620-8427-8eac0d712c71") {
          return this.SpecialEquipmentDict[1].label;
        }
        if (value === "3a01a6a5-7a6c-a19a-5514-cc8f6234d064") {
          return this.SpecialEquipmentDict[2].label;
        }
        return "";
      },
      tab: {
        post: false,
        about: true,
        video: false,
        photo: false,
        friend: false,
      },
    };
  },
  created(){
    this.businessId = this.$route.query.id;
  },
  mounted() {
    let id = this.$route.query.id;
    if (id) {
      this.GetData();
      this.GetOfficerList(); //获取安全员列表
      this.GetSpecialoperatorList(); //获取特种作业人员列表
      this.GetPressurevesselList(); //获取容压器列表
      this.readSpecialEquipmentDict(); //读取特种设备数据字典
    }
  },
  methods: {
    GetData() {
      let that = this;
      businessApi
        .GetData({
          //入参为地址栏传入的ID值
          id: that.$route.query.id,
        })
        .then((res) => that.GetSuccess(that, res))
        .finally(() => {});
    },
    GetSuccess(that, res) {
      if (res.success) {
        // console.log(res.data);
        this.BasicInfomation = res.data;
        this.GetgridheadList();
      } else {
        console.log(res);
      }
    },

    //获取特种作业人员列表
    GetSpecialoperatorList() {
      let that = this;
      businessApi
        .GetSpecialoperatorList({
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: that.id,
        })
        .then((res) => that.GetSpecialoperatorListSuccess(that, res))
        .finally(() => {});
    },
    GetSpecialoperatorListSuccess(that, res) {
      if (res.success) {
        that.SpecialoperatortableData = res.data.items;
      } else {
        console.log(res);
      }
    },

    //获取安全管理员列表
    GetOfficerList() {
      let that = this;
      businessApi
        .GetOfficerList({
          //入参为地址栏传入的ID值
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: that.id,
        })
        .then((res) => that.GetOfficerListSuccess(that, res))
        .finally(() => {});
    },
    GetOfficerListSuccess(that, res) {
      if (res.success) {
        that.OfficertableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },

    // 获取容压器列表
    GetPressurevesselList() {
      let that = this;
      businessApi
        .GetPressurevesselList({
          SkipCount: (that.tablePage.currentPage - 1) * that.tablePage.pageSize,
          MaxResultCount: that.tablePage.pageSize,
          Sorting: "",
          BusinessId: that.id,
        })
        .then((res) => that.GetPressurevesselListSuccess(that, res))
        .finally(() => {});
    },
    GetPressurevesselListSuccess(that, res) {
      if (res.success) {
        that.RtableData = res.data.items;
        that.tablePage.totalResult = res.data.totalCount;
      } else {
        console.log(res);
      }
    },
    InfomationeditEvent() {
      this.$modal.show(
        CompanyInfomation, //组件引用页面
        { Companyid: this.$route.query.id }, //入参值
        { height: "750", width: "70%", draggable: true } //跳出框参数
      );
    },

    //跳出框安全管理人员编辑页面
    editEvent() {
      if (this.id) {
        this.$modal.show(
          Officer, //组件引用页面
          { OfficerListid: this.$route.query.id }, //入参值
          { height: "520", width: "74%", draggable: true } //跳出框参数
        );
      } else {
        alert("请保存企业后添加");
      }
    },

    //跳出框容压器编辑页面
    PressurevesseleditEvent() {
      if (this.id) {
        this.$modal.show(
          Pressurevessel,
          { PressurevesselListid: this.$route.query.id },
          { height: "520", width: "60%", draggable: true }
        );
      } else {
        alert("请保存企业后添加");
      }
    },
    // 进入安全检查详情页
    SecurityCheckeditEvent(id) {
      //id为安全检查id
      this.$router.push({
        path: "/SecurityCheck?id=" + id,
      });
    },
    // 进入安全检查列表
    SecurityCheckList() {
      this.$router.push({
        path: "/SecurityCheckList?id=" + this.id,
      });
    },

    //跳出框特种做作业人员编辑页面
    SpecialoperatoreditEvent() {
      if (this.id) {
        this.$modal.show(
          Specialoperator,
          { SpecialoperatorListid: this.$route.query.id },
          { height: "520", width: "60%", draggable: true }
        );
      } else {
        alert("请保存企业后添加");
      }
    },

    //读取特种设备]数据字典
    readSpecialEquipmentDict() {
      let that = this;
      let options = [];
      that.SpecialEquipmentDict = [];
      dictApi
        .getDictUrl({
          id: "3a01a6a4-e976-402d-cd14-d2c50688e89b",
          bChild: false,
        })
        .then((res) => {
          // console.log('readSpecialEquipmentDict',res.data)
          res.data.forEach((element) => {
            options.push({
              label: element.name,
              value: element.id,
            });
          });
          that.SpecialEquipmentDict = options;
        });
    },

    //读取网格长数据字典
    GetgridheadList() {
      let that = this;
      that.gridheadEquipmentDict = "";
      gridheadApi
        .GetGridhead({ id: that.BasicInfomation.gridId })
        .then((res) => that.GetGridheadSuccess(that, res))
        .finally(() => {});
    },
    GetGridheadSuccess(that, res) {
      if (res.success) that.gridheadEquipmentDict = res.data.name;
    },
    show: function(x) {
      this.tab.post = false;
      this.tab.about = false;
      this.tab.video = false;
      this.tab.photo = false;
      this.tab.friend = false;

      switch (x) {
        case "post":
          this.tab.post = true;
          break;
        case "video":
          this.tab.video = true;
          break;
        case "photo":
          this.tab.photo = true;
          break;
        case "friend":
          this.tab.friend = true;
          break;
        default:
          this.tab.about = true;
          break;
      }
    },
  },
};
</script>
<style>
.profile-header {
  position: fixed;
  z-index: 1;
  width: 100%;
}
.profile-content {
  padding-top: 200px;
}

dd {
  min-height: 20px;
}
.invoice-content{
margin: 0 -20px;
padding: 20px 20px 0 20px;
}

dt{
  font-weight: 500;
}

</style>


